function lazyLoadMain(){
    var lazyConts = $(".lazy_container");
    var lazyImages = $(".lazy_img"); 
    for (var i = 0, len = lazyConts.length; i < len; i++) {
        (function(i) {
            // Load images if first 3 images
            if( i < 3){
                lazyImages[i].src = lazyImages[i].dataset.src;
            }
            lazyImages[i].onload = function() {
                $('span.loader').hide();
                lazyImages[i].onerror = null;
            };

            var offset = lazyConts[i].getBoundingClientRect().top + window.pageYOffset - window.innerHeight;
            var lazyFx = function() {
                if (window.pageYOffset > offset) {
                // the image is appearing in the viewport
                    lazyImages[i].src = lazyImages[i].dataset.src;
                    window.removeEventListener("scroll", lazyFx);
                    lazyFx = null;
                }
            };
            
            window.addEventListener("scroll", lazyFx);

        })(i);
    }
}
$(document).ajaxStop(function(){
    // console.log('ajax call completed');
    setTimeout(function(){ 
        lazyLoadMain();
    }, 250);
    $( "body .refinement-bar button" ).on( "click", function() {        
        // console.log( "clicking images" );
        setTimeout(function(){ 
            var lazyConts = $(".lazy_container");
            var lazyImages = $(".lazy_img");
            for (var i = 3, len = lazyConts.length; i < len; i++) {
                lazyImages[i].src = lazyImages[i].dataset.src;
            }            
        }, 600);
    });
    setTimeout(function(){ 
        $(window).scrollTop($(window).scrollTop()+0.6);
    }, 300);
});
